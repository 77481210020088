


















import {
    BSidebar,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BSpinner,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { Component, Prop, Vue } from "vue-property-decorator";
import { required, min, max } from "@validations";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { VueSelect } from "vue-select";
import { CharteGraphique, NewCharteGraphique } from "@/api/models/options/charteGraphique/charteGraphique";
import appSettings from "@/appSettings";

@Component({
  components: {
    BSidebar,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormCheckboxGroup,
    VueSelect
  },
  name: "create-charte-graphique",
})
export default class CreateCharteGraphiqueModal extends Vue {
    
  title!: string;
  loading = true;
  newCharteGraphique: NewCharteGraphique = {};

  required = required;
  min = min;
  max = max;

  async submit() {
    this.loading = true;
  }
  async createNewCharte(event: any){
        event.preventDefault();
        (this.$refs.createFormValidation as InstanceType<typeof ValidationObserver>)
      .validate().then(async (isSuccess: boolean) => {
        if (isSuccess) {           
          await this.$http.ressifnet.charteGraphique.post(this.newCharteGraphique).then(
            async (response: any) => {
              this.$bvModal.hide("modal-new-charte-graphique");
              successAlert.fire({
                title: "Création d'une charte graphique",
                text: "Création effectuée avec succés",
              });               
              this.$emit('charte-added');           
            },
            (error: any) => {
              if (error.response.data.errors) {
                errorAlert.fire({
                  text: error.response.data.errors[
                    Object.keys(error.response.data.errors)[0]
                  ],
                });
              } else {
                this.$bvModal.hide("modal-new-charte-graphique");
                errorAlert.fire({
                  text: error.message,
                });
              }
            }
          );
        }
      });
    }
}
