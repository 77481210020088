





























import { OptionModel } from '@/api/models/options/optionModel';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class DraggableOptionItem extends Vue {
  @Prop({ required: true }) optionItem!: OptionModel

  updateOptionItem() {
    this.$emit('update-option-item', this.optionItem.id)
  }

  deleteOptionItem() {
    this.$emit('delete-option-item', this.optionItem.id)
  }
}
