


















import {
    BSidebar,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BSpinner,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { Component, Prop, Vue } from "vue-property-decorator";
import { required, min, max } from "@validations";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { VueSelect } from "vue-select";
import { CharteGraphique } from "@/api/models/options/charteGraphique/charteGraphique";

@Component({
  components: {
    BSidebar,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormCheckboxGroup,
    VueSelect
  },
  name: "edit-charte-graphique",
})
export default class EditCharteGraphiqueModal extends Vue {
  @Prop({ required: true }) charteGraphique!: CharteGraphique
  title!: string;
  loading = true;

  required = required;
  min = min;
  max = max;

  async submit() {
    this.loading = true;
  }
  async updateCharte(event: any){
    event.preventDefault();
        (this.$refs.editFormValidation as InstanceType<typeof ValidationObserver>)
      .validate().then(async (isSuccess: boolean) => {
        if (isSuccess) {
          await this.$http.ressifnet.charteGraphique.put(this.charteGraphique.id,this.charteGraphique).then(
            async (response: any) => {
              this.$bvModal.hide("edit-charte-graphique");
              successAlert.fire({
                title: "Modification d'une charte graphique",
                text: "Modification effectuée avec succés",
              });    
              this.$emit('charte-added');          
            },
            (error: any) => {
              if (error.response.data.errors) {
                errorAlert.fire({
                  text: error.response.data.errors[
                    Object.keys(error.response.data.errors)[0]
                  ],
                });
              } else {
                this.$bvModal.hide("edit-charte-graphique");
                errorAlert.fire({
                  text: error.message,
                });
              }
            }
          );
        }
      });
  }
}
