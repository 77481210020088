












































































































































import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormSelect,
  BFormGroup,
  BCard,
  BAlert,
  BLink,
  BCardText,
  BRow,
  BCol,
  BSpinner,
  BFormFile,
  BFormSelectOption,
} from "bootstrap-vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import appSettings from "@/appSettings";
import { PaginatedList } from "@/api/models/common/paginatedList";
import {
  CharteGraphique,
  CouleurCharteGraphique,
  NewCharteGraphique,
  UpdateCouleurCharteGraphique,
} from "@/api//models/options/charteGraphique/charteGraphique";
import draggable from "vuedraggable";
import DraggableOptionItem from "../administration/options/shared/DraggableOptionItem.vue";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";
import { required, min, max } from "@validations";
import EditCharteGraphique from "./Modal/EditModal.vue";
import CreateCharteGraphique from "./Modal/CreateModal.vue";

@Component({
  components: {
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    BCardText,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    draggable,
    DraggableOptionItem,
    BFormSelect,
    BFormSelectOption,
    EditCharteGraphique,
    CreateCharteGraphique,
  },
})
export default class ChartesGraphique extends Vue {
  required = required;
  min = min;
  max = max;

  refreshIndex = 0;

  editColors = false;
  selectedColors: [] | undefined = [];

  chartesGraphique: CharteGraphique[] = [];

  componentData = {
    props: {
      type: "transition",
      name: "flip-list",
    },
  };

  chartesGraphiqueWithoutSave: [] = [];
  colorSeries: [
    {
      id?: string;
      text?: any;
      value?: [];
    },
  ] = [{}];

  async created() {
    await this.getCharteGraphiques();
    this.selectedColors = this.colorSeries[0].value;
  }

  async getCharteGraphiques(params?: any) {
    await this.$http.ressifnet.charteGraphique
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? "",
      )
      .then(
        (response: any) => {
          this.chartesGraphique = response["items"];
          this.chartesGraphiqueWithoutSave = JSON.parse(
            JSON.stringify(response["items"]),
          );

          this.chartesGraphique.forEach((charte: any) => {
            var colorTable: [] = [];
            charte.couleurCharteGraphiques.sort(
              (a: any, b: any) => a.ordre - b.ordre,
            );
            this.colorSeries.push({
              id: charte.id,
              text: charte.nom,
              value: colorTable,
            });
          });

          this.editColors = true;
        },
        (error: any) => {
          console.log(error);
        },
      );
  }

  async deleteCharte(charteId: any) {
    await this.$http.ressifnet.charteGraphique.delete(charteId).then(
      async (response: any) => {
        successAlert.fire({
          title: "Suppression d'une charte graphique",
          text: "Suppression effectuée avec succès",
        });
        this.getCharteGraphiques();
      },
      (error: any) => {
        errorAlert.fire({
          title: "Oops",
          text: error.message,
        });
      },
    );
  }

  async openEditModal() {
    this.$bvModal.show("edit-charte-graphique");
  }

  async addColor(charteId: any) {
    const charte = this.chartesGraphique.find((x) => x.id === charteId);
    if (charte && charte.couleurCharteGraphiques) {
      const newCouleur: CouleurCharteGraphique = {
        code: this.getRandomColor(),
        ordre: charte.couleurCharteGraphiques.length.toString(),
        charteGraphiqueId: charte.id,
      };
      this.$http.ressifnet.charteGraphique
        .addColor(charte?.id, newCouleur)
        .then(
          (response: any) => {
            successAlert.fire({
              title: "Ajout d'une couleur à la charte " + charte.nom,
              text: "Ajout effectuée avec succès",
            });
            this.getCharteGraphiques();
          },
          (error: any) => {
            console.log(error);
          },
        );
    }
  }

  getRandomColor(): string {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  }

  async removeColor(charteId: any, color: any) {
    const chart = this.chartesGraphique.find((x) => x.id === charteId);
    if (chart && chart?.couleurCharteGraphiques) {
      await this.$http.ressifnet.charteGraphique
        .removeColor(charteId, color.id)
        .then(
          (response: any) => {
            successAlert.fire({
              title: "Suppression d'une couleur",
              text: "Supression effectuée avec succès",
            });
            this.getCharteGraphiques();
          },
          (error: any) => {
            console.log(error);
          },
        );
    }
  }

  async updateColors(operation: any, index: any, charteId: any) {
    const chart = this.chartesGraphique.find((x) => x.id === charteId);

    if (chart && chart?.couleurCharteGraphiques) {
      chart.couleurCharteGraphiques[index].code = operation;
      let updated: boolean = false;
      chart.couleurCharteGraphiques.forEach(
        (color: UpdateCouleurCharteGraphique, index: any) => {
          color.ordre = index;
          this.$http.ressifnet.charteGraphique
            .updateColor(chart?.id, color)
            .then((response: any) => {
              updated = true;
            })
            .catch((e: any) => {
              console.error(e);
            });
          this.getCharteGraphiques();
        },
      );
      if ((updated = true)) {
        successAlert.fire({
          title: "Modification sur la charte " + chart.nom,
          text: "Modification effectuée avec succès",
        });
      }
    }
  }

  async updateColorOrder(chartId: string, newOrder: any) {
    const chart = this.chartesGraphique.find((x) => x.id === chartId);
    if (chart && chart?.couleurCharteGraphiques) {
      const colorToUpdate = chart.couleurCharteGraphiques.find(
        (color) => color.code === newOrder.moved.element.code,
      );
      if (colorToUpdate) {
        let updated: boolean = false;
        colorToUpdate.ordre = newOrder.moved.newIndex;
        chart.couleurCharteGraphiques.forEach(
          (color: UpdateCouleurCharteGraphique, index: any) => {
            color.ordre = index;
            this.$http.ressifnet.charteGraphique
              .updateColor(chart?.id, color)
              .then((response: any) => {
                updated = true;
              })
              .catch((e: any) => {
                console.error(e);
              });
            this.getCharteGraphiques();
          },
        );
        if ((updated = true)) {
          successAlert.fire({
            title: "Modification sur la charte " + chart.nom,
            text: "Modification effectuée avec succès",
          });
        }
      }
    }
  }
}
